import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './main/app';
import {ConfigProvider} from 'antd';
import zhCN from 'antd/locale/zh_CN';
import 'dayjs/locale/zh-cn';
import reportWebVitals from './reportWebVitals';
import dayjs from "dayjs";
import relativeTime from 'dayjs/plugin/relativeTime';
import { px2remTransformer, StyleProvider } from '@ant-design/cssinjs';

const px2rem = px2remTransformer({
    rootValue: 32, // 32px = 1rem; @default 16
});

dayjs.extend(relativeTime);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ConfigProvider locale={zhCN}>
        <StyleProvider transformers={[px2rem]}>
            <App />
        </StyleProvider>
    </ConfigProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
