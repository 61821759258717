import {Component} from 'react';
import {Spin} from 'antd';
import "./style.less";

class Loading extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tip: this.props.tip,
        };
    }
    componentDidMount() {
    }
    render() {
        return (
            <Spin tip={this.state.tip}>
                <div className="spin-wrapper-block"></div>
            </Spin>
        );
    }
}

export default Loading;

